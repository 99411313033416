export default [
  // capacitaciones
  {
    path: '/cursos',
    name: 'curso-list',
    component: () => import('@/views/cursos/curso/curso-list/CursosList.vue'),
  },
  {
    path: '/cursos/:id/versiones',
    name: 'curso-show-versiones',
    component: () => import('@/views/cursos/curso/versiones/CursoVersionList.vue'),
  },
  {
    path: '/cursos/versiones',
    name: 'curso-versiones-list',
    component: () => import('@/views/cursos/curso/versiones/CursoVersionList.vue'),
  },
  {
    path: '/cursos/versiones/nuevo',
    name: 'curso-version-add',
    component: () => import('@/views/cursos/curso/versiones/CursoAdd.vue'),
  },
  {
    path: '/cursos/versiones/:id',
    name: 'curso-version-show',
    component: () => import('@/views/cursos/curso/versiones/curso-version-view/CursoVersionView.vue'),
    children: [
      {
        path: '',
        name: 'curso-version-detalle',
        component: () => import('@/views/cursos/curso/versiones/curso-version-view/CursoVersionViewDetalle.vue'),
      },
      {
        path: 'participantes',
        name: 'curso-version-participantes',
        component: () => import('@/views/cursos/curso/versiones/curso-version-view/participantes-list/ParticipantesList.vue'),
      },
      {
        path: 'historico',
        name: 'curso-version-historico',
        component: () => import('@/views/cursos/curso/versiones/curso-version-view/UserViewUserTimelineCard.vue'),
      },
    ],
  },
  {
    path: '/cursos/versiones/:id/edit',
    name: 'curso-version-edit',
    component: () => import('@/views/cursos/curso/versiones/CursoEdit.vue'),
  },
]
