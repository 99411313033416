import ability from './ability'

const rout = route => {
  console.log('ROUTE: ', route.meta.action || 'read', route.meta.resource)
  console.log('CAN: ', ability.can(route.meta.action || 'read', route.meta.resource))
  return ability.can(route.meta.action || 'read', route.meta.resource)
}
export const canNavigate = to => to.matched.some(rout)

export const _ = undefined
