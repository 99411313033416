export default [
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/users/list',
    name: 'apps-users-list',
    component: () => import('@/views/users/users-list/UsersList.vue'),
  },
  {
    path: '/users/view/:id',
    name: 'apps-users-view',
    component: () => import('@/views/users/users-view/UsersView.vue'),
  },
  {
    path: '/users/edit/:id',
    name: 'apps-users-edit',
    component: () => import('@/views/users/users-edit/UsersEdit.vue'),
  },
]
