export default [
  // Categorias
  {
    path: '/categorias',
    name: 'categoria-list',
    component: () => import('@/views/cursos/categoria/categoria-list/CategoriasList.vue'),
  },
  // Carreras
  {
    path: '/carreras',
    name: 'carrera-list',
    component: () => import('@/views/cursos/carrera/carrera-list/CarrerasList.vue'),
  },
  // Faq
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/views/cursos/detalle/faq/FaqList.vue'),
  },
  // Beneficios
  {
    path: '/beneficios',
    name: 'beneficio',
    component: () => import('@/views/cursos/detalle/beneficio/BeneficioList.vue'),
  },
  // Tag
  {
    path: '/tags',
    name: 'tag',
    component: () => import('@/views/cursos/detalle/tag/TagList.vue'),
  },
  // Pais
  {
    path: '/pais',
    name: 'pais',
    component: () => import('@/views/cursos/detalle/pais/PaisList.vue'),
  },
  // Modalidad
  {
    path: '/modalidad',
    name: 'modalidad',
    component: () => import('@/views/cursos/detalle/modalidad/Modalidad.vue'),
  },
  // Metodo-Pago
  {
    path: '/metodo-pago',
    name: 'metodo-pago',
    component: () => import('@/views/cursos/detalle/metodo-pago/MetodoPagoList.vue'),
  },

  // *===============================================---*
  // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
  // *===============================================---*
  {
    path: '/capacitaciones/email',
    name: 'capacitaciones-email',
    component: () => import('@/views/cursos/curso/versiones/CursoEdit.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
    },
  },
  {
    path: '/capacitaciones/email/:folder',
    name: 'capacitaciones-email-folder',
    component: () => import('@/views/cursos/curso/versiones/CursoEdit.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/capacitaciones/email/label/:label',
    name: 'capacitaciones-email-label',
    component: () => import('@/views/cursos/curso/versiones/CursoEdit.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
      else next({ name: 'error-404' })
    },
  },

  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/todo',
    name: 'apps-todo',
    component: () => import('@/views/cursos/curso/versiones/CursoEdit.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
  },
  {
    path: '/apps/todo/:filter',
    name: 'apps-todo-filter',
    component: () => import('@/views/cursos/curso/versiones/CursoEdit.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/todo/tag/:tag',
    name: 'apps-todo-tag',
    component: () => import('@/views/cursos/curso/versiones/CursoEdit.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
      else next({ name: 'error-404' })
    },
  },

]
